import { Fragment, h } from 'preact'
import classNames from 'clsx'

import BoxTitle from '../BoxTitle'
import SmartImg from '../nbb-smart-img'
import LinkWrapper from '../link-wrapper'

import styles from './index.module.scss'

export const ImageArticle = (props) => {
  const {
    href,
    title,
    picture,
    small,
    rectangle,
    smallTitle,
    bigTitle,
    strokedTitle,
    shadowedTitle,
    noTitlePadding,
  } = props

  return (
    <article className={buildClassNames({ small, rectangle })}>
      <LinkWrapper href={href} title={title} rel="bookmark">
        <header className={styles['a-image-article__wrapper']}>
          <BoxTitle
            small={smallTitle}
            big={bigTitle}
            stroked={strokedTitle}
            shadowed={shadowedTitle}
            className={classNames(
              styles['a-image-article__title'],
              { [styles['a-image-article__title--no-padding']]: noTitlePadding },
            )}>
            {title}
          </BoxTitle>

          {picture && picture.src && (
            <span className={styles['a-image-article__background']}>
              <SmartImg alt={title} {...picture} />
            </span>
          )}
        </header>
      </LinkWrapper>
    </article>
  )
}

function buildClassNames({ small, rectangle }) {
  return classNames(
    styles['a-image-article'],
    {
      [styles['a-image-article--small']]: small,
      [styles['a-image-article--rectangle']]: rectangle,
    },
  )
}

export function GenerateImageArticleCollection({
  entries = [],
  Wrapper = Fragment,
  ...props
}) {
  return entries.map((entry, idx) => (
    <Wrapper key={entry.id || idx}>
      <ImageArticle
        {...props}
        {...entry}
        picture={{ ...entry.picture, ...props.picture }}
      />
    </Wrapper>
  ))
}

export default ImageArticle
