import { h, toChildArray } from 'preact'
import classNames from 'clsx'

import styles from './index.module.scss'

const GridList = ({ children, balanced, className }) => {
  children = toChildArray(children)

  return (
    <ul className={buildClassNames({
      className,
      balanced,
      length: children.length,
    })}>
      {children}
    </ul>
  )
}

function buildClassNames({ className, balanced, length }) {
  return classNames(
    className,
    styles['a-grid-list'],
    {
      [styles['a-grid-list--balanced']]: balanced,
      [styles['a-grid-list--even']]: length % 2 === 0,
    },
  )
}

export const GridListEntry = ({ children, className, ...rest }) => (
  <li {...rest} className={buildEntryClassNames({ className })}>
    {children}
  </li>
)

function buildEntryClassNames({ className }) {
  return classNames(
    className,
    styles['a-grid-list__entry'],
  )
}

export default GridList
