import { h } from 'preact'
import classNames from 'clsx'

import styles from './index.module.scss'

const BoxTitle = ({
  children, className, stroked, small, big, shadowed, ...rest
}) => (
  <h1 className={classNames(
    className,
    styles['a-box-title'],
    {
      [styles['a-box-title--shadowed']]: shadowed,
      [styles['a-box-title--stroked']]: stroked,
      [styles['a-box-title--small']]: small,
      [styles['a-box-title--big']]: big,
    },
  )} {...rest}>
    {children}
  </h1>
)

export default BoxTitle
